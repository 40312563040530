<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="fe fe-spinner">Loading..</span>
			</div>
		</div>
		<div v-else>
			<div class="row">
				<div class="col-12 col-xl-6">
					<div class="card">
						<div class="card-header justify-content-between">
							<h4 class="card-header-title">Company Information</h4>
							<div class="dropdown">
								<button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
									Actions
								</button>
												<div
										class="dropdown-menu dropdown-menu-right"
										aria-labelledby="dropdownMenuButton"
										> 
										<router-link
											v-if="userHasPermission('corporates004')"
											:to="{
												name: 'EditCompany',
												params: { companyId: companyId },
											}"
											class="dropdown-item" 
											>Update Information</router-link
											>
										<a
											v-if="userHasPermission('corporates006')"
											class="dropdown-item py-2"
											href="#"
											@click.prevent="openWalletPaymentOptions()"
											>Wallet payment options</a
											>

										<a
											class="dropdown-item py-2"
											href="#"
											@click.prevent="openCancellationModal()"
											v-if="userHasPermission('corporates007')"
											>Booking Cancellation Config</a
											>
										<a
											class="dropdown-item py-2"
											href="#"
											@click.prevent="openDateSelectionModal()"
											>Export manifest</a
											>
										<a
											v-if="userHasPermission('corporates005')"
											class="dropdown-item py-2"
											:class="isCompanyActive ? 'text-danger' : 'text-success'"
											href="#"
											@click.prevent="suspendOrUnsuspendCompany"
											>{{
												isCompanyActive ? 'Suspend' : 'Unsuspend'
											}}
											Company</a
											>
									</div>
							</div>
						</div>
						<div class="card-body p-2">
							<div class="list-group list-group-flush mb-4">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Company Name</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												company?.corporate_name ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Phone Number</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												company?.corporate_phone ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Email Address</span>
										</div>
										<div class="col-auto">
											<span class="">{{ company?.email ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Office Address</span>
										</div>
										<div class="col-auto">
											<span class="">{{
												company?.corporate_address ?? 'N/A'
											}}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Industry</span>
										</div>
										<div class="col-auto">
											<span class="">{{ company?.industry ?? 'N/A' }}</span>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<span class="text-muted text-uppercase">Status</span>
										</div>
										<div class="col-auto">
											<span class="badge" :class="isCompanyActive
												? 'badge-success px-4 py-2'
												: 'badge-danger'
											">{{ isCompanyActive ? 'Active' : 'Inactive' }}</span>
										</div>
									</div>
								</div>
							</div>
							<div>
								<hr />
								<div class="card-header justify-content-between">
									<h4 class="card-header-title">Administrator Information</h4>
								</div>
								<div class="list-group list-group-flush mb-4">
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<span class="text-muted text-uppercase">Name</span>
											</div>
											<div class="col-auto">
												<span v-if="company?.fname || company?.lname" class="">
													{{ `${company?.fname}` }} {{ `${company?.lname}` }}
												</span>
												<span v-else>N/A</span>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<span class="text-muted text-uppercase">Phone Number</span>
											</div>
											<div class="col-auto">
												<span class="">{{
													company?.person_phone ?? 'N/A'
												}}</span>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<span class="text-muted text-uppercase">Email Address</span>
											</div>
											<div class="col-auto">
												<span class="">{{
													company?.person_email ?? 'N/A'
												}}</span>
											</div>
										</div>
									</div>
									<div class="list-group-item">
										<div class="row align-items-center">
											<div class="col">
												<span class="text-muted text-uppercase">ROLE</span>
											</div>
											<div class="col-auto">
												<span class="">{{ company?.staff_role ?? 'N/A' }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-6">

					<b-card>
						<template v-if="!branchLoading">
							<template v-if="branches.length">
								<div class="flex items-center justify-between">
									<h4 class="m-0">Work branches</h4>
									<button class="w-fit bg-white border-1 border-black text-black rounded py-1 px-2" @click="openModal(addBranch, companyId)">Add work branch</button>
								</div>
								<hr />
								<div>
									<div class="flex flex-col gap-2">
										<div class="col-12" v-for="(work_branch, index) in branches" :key="index">
											<!-- <label class="form-label">Office {{ work_branch.name }}</label> -->
											<div class="input-group border rounded">
												<input type="text" readonly style="outline: none" :placeholder="`${work_branch.name} - ${work_branch.address}`" class="form-control shadow-none border-0" aria-label="Amount (to the nearest dollar)" />
												<div class="input-group-append">
													<span style="cursor: pointer" @click="editWorkBranchs(work_branch)" class="input-group-text border-0"><img src="@/assets/img/edit_icon.svg" alt="" /></span>
													<span style="cursor: pointer" @click="deleteBranch(companyId, work_branch, $swal)" class="input-group-text border-0"><img src="@/assets/img/delete_icon.svg" alt="" /></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<div v-else class="flex flex-col items-center gap-1 py-5">
								<h3 class="text-[#101828] font-medium m-0 text-base">No work branch available</h3>
								<p class="text-xs text-[#667085] m-0 font-medium">Add a new branch</p>
								<button class="bg-[#101211] border-0 mt-3 text-white rounded px-3 py-2 w-fit" @click="openModal(addBranch, companyId)">Add work branch</button>
							</div>
						</template>
						<div v-else class="center text-center p-4">
							<span class="fe fe-spinner">Loading..</span>
						</div>
					</b-card>

					<b-card>
						<template v-if="!shiftLoading">
							<template v-if="shifts.length">
								<div class="flex items-center justify-between">
									<h4 class="m-0">Work shifts</h4>
									<button class="w-fit bg-white border-1 border-black text-black rounded py-1 px-2" @click="openModal(addShift, companyId)">Add work shift</button>
								</div>
								<hr />
								<div>
									<div class="flex flex-col gap-2">
										<div class="col-12" v-for="(work_shift, index) in shifts" :key="index">
											<div class="input-group border rounded">
												<input type="text" readonly :placeholder="`${work_shift.start_time} - ${work_shift.end_time}`" class="form-control shadow-none border-0 font-bold text-[#000005]" style="outline: none" aria-label="" />
												<div class="input-group-append">
													<span style="cursor: pointer" @click="editWorkShifts(work_shift)" class="input-group-text border-0"><img src="@/assets/img/edit_icon.svg" alt="" /></span>
													<span style="cursor: pointer" @click="deleteWorkShifts(companyId, work_shift, $swal)" class="input-group-text border-0"><img src="@/assets/img/delete_icon.svg" alt="" /></span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>
							<div v-else class="flex flex-col items-center gap-1 py-5">
								<h3 class="text-[#101828] font-medium m-0 text-base">No work shifts available</h3>
								<p class="text-xs text-[#667085] m-0 font-medium">Add a new work shift</p>
								<button class="bg-[#101211] border-0 mt-3 text-white rounded px-3 py-2 w-fit" @click="openModal(addShift, companyId)">Add work shift</button>
							</div>
						</template>
						<div v-else class="center text-center p-4">
							<span class="fe fe-spinner">Loading..</span>
						</div>
					</b-card>
				</div>
			</div>

			<div class="row mt-3">
				<div class="col-12">
					<h3>Business account managers</h3>
				</div>
				<div class="col-12">
					<BusinessAccountManager :companyId="companyId" v-if="userHasPermission('corporates012')" />
					<div class="card p-4 bg-white" v-else>
						Unauthorised
					</div>
				</div>
			</div>
		</div>

		<topup-wallet-modal :show="showTopupWallet" :wallet="wallet" @close="showTopupWallet = false" @wallet-funded="fetchCompanyWallet"></topup-wallet-modal>

		<update-confirmation-modal indexKey="walletOptionsModal" :is-password-type="true" :show="showUpdateWalletOptions" modal-title="Corporate Wallet Options" :update-function="saveWalletOptionUpdate" @close="closeWalletPaymentOptions" :processing="savingWalletOptionChanges"
			prompt-text="Please enter your password to save your changes" prompt-key="your password" verification-message="Are you sure you want to save this changes ?" :can-proceed="true">
			<template>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.publicRoutesRestricted">
						Restrict bookings on public routes
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.publicRoutesRestrictedWithCorpWallet">
						Restrict booking on public routes with company wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.privateRoutesRestrictedWithPersonalWallet
					">
						Restrict booking on private routes with personal wallet
					</b-form-checkbox>
				</div>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="walletOptionsForm.privateRoutesRestrictedWithCorporateWallet
					">
						Restrict booking on private routes with company wallet
					</b-form-checkbox>
				</div>
			</template>
		</update-confirmation-modal>

		<update-confirmation-modal indexKey="bookingCancellationModal" :is-password-type="true" :show="showBookingCancellationModal" modal-title="Booking cancellations modal" :update-function="saveBookingCancellationOptionUpdate" @close="closeCancellationModal"
			:processing="savingBookingCancellationOptionChanges" prompt-text="Please enter your password to save your changes" prompt-key="your password" verification-message="Are you sure you want to save this changes ?" :can-proceed="true">
			<template>
				<div class="form-group mb-3">
					<b-form-checkbox class="v-checkbox" v-model="bookingCancellationOptionsForm.restrict_admin_bookings_cancellation
					">
						Enable booking cancellation on admin
					</b-form-checkbox>
				</div>
			</template>
		</update-confirmation-modal>

		<b-modal :id="modalId" ref="manifestModal" @close="closeDateSelectionModal" size="sm" no-close-on-backdrop no-close-on-esc>
			<template #modal-title> Manifest report (Select date range) </template>
			<div class="py-2">
				<div class="form">
					<div class="form-group">
						<label class="form-label mb-0">Start Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.startDate" style="width: 100%"></v-datepicker>
						</div>
					</div>

					<div class="form-group">
						<label class="form-label mb-0">End Date</label>
						<div style="width: 100%">
							<v-datepicker v-model="form.endDate" style="width: 100%"></v-datepicker>
						</div>
					</div>
				</div>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button :disabled="downloadingReport" variant="primary" class="float-right" @click="generateCustomRangeReport()">
						{{ downloadingReport ? 'Processing' : 'Generate' }}
					</b-button>
					<b-button :disabled="downloadingReport" variant="secondary" class="float-right mr-2" @click="closeDateSelectionModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
		<BS_modal :show='isRV_Modal' title='Route Visibility settings' @close='isRV_Modal = false' modalId='isRV_Modal'>
			<div class="form-group mb-3">
				<b-form-checkbox class="v-checkbox" @change="updateStaffVisibility($event)" v-model='checkboxModel' :disabled='visibilityLoading'>
					Prevent staff from seeing public routes
					<div class="spinner-border w-3 h-3" role="status" v-if='visibilityLoading'></div>
				</b-form-checkbox>

			</div>
		</BS_modal>
	
	</div>
</template>

<script setup>
import Swal from 'sweetalert2'
import TopupWalletModal from '@/components/modals/TopupWalletModal.vue'
import UpdateConfirmationModal from '@/components/modals/UpdateConfirmationModal.vue'
import { extractErrorMessage } from '@/utils/helpers'
import { format } from 'date-fns'
import BusinessAccountManager from './BusinessAccountManager.vue'
import { useModal } from '@/composables/core/modal'
import addShift from '../../modals/addShift.vue'
import editShift from '../../modals/editShift.vue'
import addBranch from '../../modals/addBranch.vue'
import editBranch from '../../modals/editBranch.vue'
import { useShifts } from '@/composables/backend/companies/workshift'
import { useBranch } from '@/composables/backend/companies/branch'
import { onMounted, ref, defineProps, computed, reactive } from 'vue'
import { axiosInstance as axios } from '@/plugins/axios'
import { userHasPermission } from '@/composables/core/permissions'
import BS_modal from '@/components/core/modals/BS_modal.vue'



const props = defineProps(['companyId'])
const { openModal } = useModal()
const { processing: shiftLoading, fetchWorkShifts, shifts, deleteWorkShift: deleteWorkShifts } = useShifts()
const { processing: branchLoading, fetchBranches, branches, form: branchForm, locationLat, locationLng, deleteBranch } = useBranch()

const manifestModal = ref(null)
const company = ref(null)
const companySetting = reactive({})
const isRV_Modal = ref(false)



const loading = ref(true)
const errorLoading = ref(false)




const wallet = ref(null)
const walletHistory = ref(null)

const loadingCompanyWallet = ref(true)
const errorLoadingCompanyWallet = ref(false)
const visibilityLoading = ref(false)

const showTopupWallet = ref(false)
const showUpdateWalletOptions = ref(false)
const showBookingCancellationModal = ref(false)
const savingWalletOptionChanges = ref(false)
const savingBookingCancellationOptionChanges = ref(false)
const walletOptionsForm = ref({
	publicRoutesRestricted: null,
	publicRoutesRestrictedWithCorpWallet: null,
	privateRoutesRestrictedWithPersonalWallet: null,
	privateRoutesRestrictedWithCorporateWallet: null
})
const bookingCancellationOptionsForm = ref({
	restrict_admin_bookings_cancellation: null,
	verification_password: ''
})

const checkboxModel = computed(() => {
  return companySetting.prevent_staff_view_of_public_routes
})

const modalId = ref('manifest-report-generation-modal')
const form = ref({
	startDate: new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`),
	endDate: new Date()
})

const updateStaffVisibility = (e) => {
	visibilityLoading.value = true
	axios.post(`/v1/corporates/${company.value.id}/settings`, { prevent_staff_view_of_public_routes: e })
		.then((response) =>
		{
			isRV_Modal.value = false
			visibilityLoading.value = false
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: 'Staff visibility settings updated successfully'
			})
		}).catch((error) =>
		{
			visibilityLoading.value = false
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: extractErrorMessage(error)
			})
		}).finally(() =>
		{
			visibilityLoading.value = false
		})
}
const downloadingReport = ref(false)


const isCompanyActive = computed(() =>
{
	if (!company.value) {
		return false
	}

	return !!parseInt(company.value.active)
})

const openDateSelectionModal = () =>
{
	// this.$bvModal.show(this.modalId)
	manifestModal.value.show()
}
const closeDateSelectionModal = () =>
{
	// this.$bvModal.hide(this.modalId)
	manifestModal.value.hide()
	form.value = {
		startDate: new Date(
			`${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`
		),
		endDate: new Date()
	}
}
const fetchAndDownloadReport = (params, customRange = false) =>
{
	downloadingReport.value = true
	return axios
		.post(`/v1/corporates-report/${company.value.id}/route-manifest`, {
			start_date: params.start_date,
			end_date: params.end_date
		})
		.then((res) =>
		{
			const result = handleZipDownload(res.data.url)
		})
		.catch(() =>
		{
			Swal.fire({
				icon: 'error',
				title: 'Download error',
				text: 'We could not process download for this report',
				showCloseButton: true
			})
		})
		.finally(() =>
		{
			downloadingReport.value = false
			if (customRange) {
				closeDateSelectionModal()
			}
		})
}

const handleZipDownload = (url) =>
{
	const link = document.createElement('a')
	link.href = url

	link.setAttribute('target', '_blank')

	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
	Swal.fire({
		icon: 'success',
		title: 'Download success',
		text: 'Report was successfully downloaded',
		showCloseButton: true
	})
}
const generateCustomRangeReport = () =>
{
	const data = {
		start_date: format(form.value.startDate, 'yyyy-MM-dd'),
		end_date: format(form.value.endDate, 'yyyy-MM-dd')
	}
	fetchAndDownloadReport(data, true)
}



const init = async () =>
{
	loading.value = true
	errorLoading.value = false

	fetchCompany()
		.then(() =>
		{
			fetchCompanyWallet()
		})
		.catch(() => (errorLoading.value = true))
		.finally(() => (loading.value = false))
}
const fetchCompany = () =>
{

	return Promise.all([
		axios.get(`/v1/corporates/${props.companyId}`),
		axios.get(`/v1/corporates/${props.companyId}/settings`)
	])
		.then((res) =>
		{
			company.value = res[0].data
			companySetting = res[1].data

		})
		.finally(() =>
		{
			setWalletPaymentOptions()
		})
}

const setWalletPaymentOptions = () =>
{
	if (company.value) {
		walletOptionsForm.value.publicRoutesRestricted =
			!!company.value.restrict_booking_on_public_routes
		walletOptionsForm.value.publicRoutesRestrictedWithCorpWallet =
			!!company.value.restrict_booking_on_public_routes_with_corporate_wallet
		walletOptionsForm.value.privateRoutesRestrictedWithPersonalWallet =
			!!company.value.restrict_booking_on_private_routes_with_personal_wallet
		walletOptionsForm.value.privateRoutesRestrictedWithCorporateWallet =
			!!company.value
				.restrict_booking_on_private_routes_with_corporate_wallet
	}
}
const setBookingCancellationOptions = () =>
{
	if (company.value) {
		bookingCancellationOptionsForm.value.restrict_admin_bookings_cancellation =
			!!company.value.restrict_admin_bookings_cancellation
	}
}
const fetchCompanyWallet = () =>
{
	loadingCompanyWallet.value = true
	errorLoadingCompanyWallet.value = false

	axios
		.get(`/v1/users/${props.companyId}/wallet`)
		.then((res) =>
		{
			wallet.value = res.data.wallet
			walletHistory.value = res.data.walletHistory
		})
		.catch(() => (errorLoadingCompanyWallet.value = true))
		.finally(() => (loadingCompanyWallet.value = false))
}

const suspendOrUnsuspendCompany = async () =>
{
	const isSuspendAction = isCompanyActive.value

	await Swal.fire({
		icon: 'question',
		title: 'Please Confirm',
		text: `Are you sure you want to ${isCompanyActive.value ? 'suspend' : 'unsuspend'} this company?`,
		showConfirmButton: true,
		showCancelButton: true,
		preConfirm: () =>
		{
			return axios
				.patch(`/v1/corporates/${props.companyId}`, {
					active: isCompanyActive.value ? 0 : 1
				})
				.then((res) =>
				{
					company.value = res.data

					return res.data
				})
				.catch((error) =>
				{
					const msg =
						error.response && error.response.data
							? error.response.data.message
							: 'An error occured, please try again.'

					// this.$swal().showValidationMessage(msg)
					Swal.fire({
						icon: 'error',
						title: 'Error',
						text: msg,
					})
				})
		},
		allowOutsideClick: () => !Swal.isLoading()
	}).then((result) =>
	{
		if (result.isConfirmed) {
			Swal.fire({
				icon: 'success',
				title: 'Success',
				text: `Company has been ${isSuspendAction ? 'suspended' : 'unsuspended'
					} successfully`,
				showCloseButton: true
			})
		}
	})
}
const openWalletPaymentOptions = () =>
{
	showUpdateWalletOptions.value = true
	setWalletPaymentOptions()
}
const openCancellationModal = () =>
{
	showBookingCancellationModal.value = true
	setBookingCancellationOptions()
}
const closeWalletPaymentOptions = () =>
{
	showUpdateWalletOptions.value = false
}
const closeCancellationModal = () =>
{
	showBookingCancellationModal.value = false
}
const saveWalletOptionUpdate = (confirmationParams) =>
{
	if (!confirmationParams.isVerificationStep) {
		confirmationParams.gotoVerification()
	} else {
		const payload = {
			restrict_booking_on_public_routes:
				walletOptionsForm.value.publicRoutesRestricted,
			restrict_booking_on_public_routes_with_corporate_wallet:
				walletOptionsForm.value.publicRoutesRestrictedWithCorpWallet,
			restrict_booking_on_private_routes_with_personal_wallet:
				walletOptionsForm.value.privateRoutesRestrictedWithPersonalWallet,
			restrict_booking_on_private_routes_with_corporate_wallet:
				walletOptionsForm.value.privateRoutesRestrictedWithCorporateWallet,
			verification_password: confirmationParams.passKey
		}
		savingWalletOptionChanges.value = true
		axios
			.patch(`v1/corporates/${props.companyId}`, payload)
			.then(() => fetchCompany())
			.then(() =>
			{
				showUpdateWalletOptions.value = false
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Changes saved successfully!',
					showCloseButton: true
				})
			})
			.catch((e) =>
			{
				const msg = extractErrorMessage(
					e,
					'An error occurred. Please try again later'
				)
				// this.$toastr.e(msg)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: msg
				})
			})
			.finally(() =>
			{
				savingWalletOptionChanges.value = false
			})
	}
}
const saveBookingCancellationOptionUpdate = (confirmationParams) =>
{
	if (!confirmationParams.isVerificationStep) {
		confirmationParams.gotoVerification()
	} else {
		const payload = {
			restrict_admin_bookings_cancellation:
				bookingCancellationOptionsForm.value
					.restrict_admin_bookings_cancellation,
			verification_password: confirmationParams.passKey
		}
		savingBookingCancellationOptionChanges.value = true
		axios
			.patch(`v1/corporates/${props.companyId}`, payload)
			.then(() => fetchCompany())
			.then(() =>
			{
				showBookingCancellationModal.value = false
				Swal.fire({
					icon: 'success',
					title: 'Success',
					text: 'Changes saved successfully!',
					showCloseButton: true
				})
			})
			.catch((e) =>
			{
				const msg = extractErrorMessage(
					e,
					'An error occurred. Please try again later'
				)
				// this.$toastr.e(msg)
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: msg
				})
			})
			.finally(() =>
			{
				savingBookingCancellationOptionChanges.value = false
			})
	}
}


const editWorkShifts = (work_shift) =>
{
	openModal(editShift, {
		start_time: work_shift.start_time,
		end_time: work_shift.end_time,
		companyId: props.companyId,
		shiftId: work_shift.id
	})
}

const editWorkBranchs = (branch_obj) =>
{
	branchForm.value.address = branch_obj.address
	branchForm.value.name = branch_obj.name
	locationLat.value = branch_obj.location.y
	locationLng.value = branch_obj.location.x
	openModal(editBranch, { companyId: props.companyId, branch_obj: branch_obj })
}

onMounted(() =>
{
	fetchWorkShifts(props.companyId)
	fetchBranches(props.companyId)
})


init()
</script>

<style lang="scss" scoped>
::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: black;
	font-weight: 100;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: black;
	font-weight: 100;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: black;
	font-weight: 100;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: black;
	font-weight: 100;
}
</style>
